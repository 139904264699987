import {atom} from "jotai";
import { UserSession } from "@/data/user-session";
import Repository from "@/repository";

export const repoAtom = atom(() => {
    const repo = new Repository();
    // repo.restoreAccessToken();
    return repo;
});

export const userSessionAtom = atom<UserSession|null|undefined>(undefined);
