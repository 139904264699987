import {StrictMode} from 'react';
import AppContent from './App';
import {createRoot} from "react-dom/client";
import {ConfigProvider, App} from "antd";
import './index.css';
import './i18n/config';

const root = createRoot(document.getElementById("root")!);

root.render(<StrictMode>
    <ConfigProvider theme={{token: {colorPrimary: '#1DA57A'}}}>
        <App><AppContent/></App>
    </ConfigProvider>
</StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
