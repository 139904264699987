import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const LANGUAGE_PERSIST_ITEM = "lang";

const ld = new LanguageDetector(null, {
    order: ['querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag'] ,
    caches: []
});

const basename = process.env.REACT_APP_BASE_PATH;

i18n.use(Backend)
    .use(ld)
    .use(initReactI18next).init({
    fallbackLng: 'zh',
    debug: false,
    backend: { loadPath: `${basename ? `/${basename}` : ''}/locales/{{lng}}/{{ns}}.json` },
    interpolation: {escapeValue: false, /* not needed for react as it escapes by default */},
}).then(() => {
    const lang = localStorage.getItem(LANGUAGE_PERSIST_ITEM) ?? 'zh';
    return lang && lang != i18n.language ? i18n.changeLanguage(lang) : null;
});

export const setCurrentLang = (lang?: string) => lang ? localStorage.setItem(LANGUAGE_PERSIST_ITEM, lang) : localStorage.removeItem(LANGUAGE_PERSIST_ITEM);
